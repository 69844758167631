import { V2_ACTIONS } from '../../actions/actionTypes'
import {
    isFeatureEnabled,
    isValidEmail,
    isValidLatinChars,
    isValidLatinNumbers,
    isValidSpecialChars,
} from '../../../main'
import { featureToggles } from '../../constants'

const INITIAL_STATE = {
    validations: {
        userPaymentDetails: {
            currency: {
                type: 'string',
                required: true,
                errorMessages: {
                    type_error: 'type_error',
                    required_error: 'payment_page.placeholder.currency',
                },
            },
            iban: {
                type: 'string',
                required: true,
                errorMessages: {
                    type_error: 'type_error',
                    required_error: 'common.label.is_required.account',
                },
            },
            swift: {
                type: 'string',
                required: true,
                lengthError: true,
                validSwiftLatin: true,
                errorMessages: {
                    length_error: 'commons.label.swift_code_length',
                    type_error: 'type_error',
                    required_error: 'common.label.is_required.swift',
                    validSwiftLatin_error:
                        'commons.label.swift_code_only_latin',
                },
            },
            userName: {
                type: 'string',
                required: true,
                validLatin: true,
                errorMessages: {
                    type_error: 'type_error',
                    required_error: 'common.label.is_required.name',
                    validLatin_error: 'common.label.is_required.latin_only',
                },
            },
            userSurname: {
                type: 'string',
                required: true,
                validLatin: true,
                errorMessages: {
                    type_error: 'type_error',
                    required_error: 'common.label.is_required.surname',
                    validLatin_error: 'common.label.is_required.latin_only',
                },
            },
            bankName: {
                type: 'string',
                required: true,
                validSymbols: true,
                errorMessages: {
                    type_error: 'type_error',
                    required_error: 'common.label.is_required.bank_name',
                    validSymbols_error:
                        'common.label.is_required.special_symbols',
                },
            },
            bankAddress: {
                type: 'string',
                required: false,
                validSymbols: true,
                errorMessages: {
                    type_error: 'type_error',
                    required_error: 'common.label.is_required.city',
                    validSymbols_error:
                        'common.label.is_required.special_symbols',
                },
            },
            bankCountry: {
                type: 'string',
                required: true,
                errorMessages: {
                    type_error: 'type_error',
                    required_error: 'common.label.is_required.bank_country',
                },
            },
        },
    },
    errors: {},
}

const validateUserInputs = (
    validations,
    payload,
    checkRequired = true,
    options = {}
) => {
    let errors = {}
    const { skip, iban } = options

    const isSwiftValidationEnabled = isFeatureEnabled(
        featureToggles.isSwiftValidationEnabled
    )
    return Object.keys(validations).reduce((errors, validator) => {
        if (skip && skip.includes(validator)) return errors

        if (typeof payload[validator] !== validations[validator].type) {
            errors[validator] = validations[validator].errorMessages.type_error
        }

        if (
            validations[validator].minLength &&
            payload[validator].length < validations[validator].minLength
        ) {
            errors[validator] = validations[validator].errorMessages.min_error
        }

        if (
            checkRequired &&
            validations[validator].required &&
            !payload[validator] &&
            validator === 'iban'
        ) {
            errors[validator] = `common.label.is_required.${
                iban ? 'account' : 'iban'
            }`
        } else if (
            checkRequired &&
            validations[validator].required &&
            !payload[validator]
        ) {
            errors[validator] =
                validations[validator].errorMessages.required_error
        }

        if (
            validations[validator].emailValid &&
            !isValidEmail(payload[validator])
        ) {
            errors[validator] =
                validations[validator].errorMessages.emailValid_error
        }

        if (
            validations[validator].validLatin &&
            !isValidLatinChars(payload[validator])
        ) {
            errors[validator] =
                validations[validator].errorMessages.validLatin_error
        }

        if (
            isSwiftValidationEnabled &&
            validations[validator].validSwiftLatin &&
            !isValidLatinNumbers(payload[validator])
        ) {
            errors[validator] =
                validations[validator].errorMessages.validSwiftLatin_error
        }

        if (
            isSwiftValidationEnabled &&
            validations[validator].lengthError &&
            !(payload[validator].length > 7 && payload[validator].length < 12)
        ) {
            errors[validator] =
                validations[validator].errorMessages.length_error
        }

        if (
            validations[validator].validSymbols &&
            !isValidSpecialChars(payload[validator])
        ) {
            errors[validator] =
                validations[validator].errorMessages.validSymbols_error
        }

        return errors
    }, errors)
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.SET_PAYMENT_ERRORS:
            const paymentErrors = validateUserInputs(
                state.validations.userPaymentDetails,
                action.payload,
                action.checkRequired,
                action.options
            )

            return {
                ...state,
                errors: {
                    ...paymentErrors,
                },
            }
        default:
    }

    return state
}
