// eslint-disable-file  no-loop-func
import React from 'react'
import MemoryStorage from 'memorystorage'
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed'
import moment from 'moment'
import Cookies from 'js-cookie'
import axios from 'axios'
import store from './shared/services/store'
import _get from 'lodash/get'
import ApiClient from './shared/services/api/client'
import { getConfig, getAirports, getAirlines } from './shared/actions'
import t from './shared/translations'
import {
    configTypes,
    paths,
    modalTypes,
    featureToggles,
} from './shared/constants'
import { renderAirports, renderAirlines, arrayUnique } from './render'
import { V2_ACTIONS } from './shared/actions/actionTypes'
import scrollIntoView from 'scroll-into-view'
import { getAirportById, showModal } from './shared/actions/v2_actions'
import { getEnTrans, logKibana } from './shared/actions/actions'

const FLIGHTDATEFORMAT = 'DD-MM-YYYY'
var scrollTimeout

export function handleMobileInputBlur() {
    const pageContent = document.getElementById('wrap')
    if (pageContent) {
        setTimeout(() => {
            if (!!store.getState().hideCookies) {
                pageContent.classList.remove('mobile-focused')
                store.dispatch({
                    type: V2_ACTIONS.HIDE_COOKIES,
                    payload: false,
                })
            }
        }, 500)
    }
}

// Contains duplicates for development purposes @TODO might be removed in the future
export function handleMobileInputFocus(element, isElement, delay, offset) {
    if (
        store &&
        typeof store.getState === 'function' &&
        store.getState().toggle_modal
    ) {
        const modal = store.getState().toggle_modal
        if (modal && !!modal.modalType) {
            return
        }
    }
    if (isMobileDevice()) {
        const action = () => {
            const elem =
                (element && isElement
                    ? element
                    : document.getElementById(element)) ||
                document.activeElement
            if (elem && typeof _get(elem, 'closest', null) === 'function') {
                scrollIntoView(elem.closest('.item-col'), {
                    vertical: 0,
                    center: false,
                    align: { top: offset || 0.1 },
                })
                store.dispatch({ type: V2_ACTIONS.HIDE_COOKIES, payload: true })
            }
        }

        if (delay) {
            setTimeout(() => {
                action()
            }, delay)
        } else {
            action()
        }
    } else {
        const action = () => {
            const elem =
                (element && isElement
                    ? element
                    : document.getElementById(element)) ||
                document.activeElement
            if (elem && typeof _get(elem, 'closest', null) === 'function') {
                scrollIntoView(elem.closest('.item-col'), {
                    vertical: 0,
                    center: false,
                    align: { top: 0.5 },
                })
            }
        }

        if (delay) {
            setTimeout(() => {
                action()
            }, delay)
        } else {
            action()
        }
    }
}

export function scrollComponentToView(id, idIsElement, skipOffset, topOffset) {
    const offsetObj = skipOffset ? {} : { offsetTop: topOffset || 10 }
    if (scrollTimeout) {
        clearTimeout(scrollTimeout)
        scrollTimeout = null
    }
    let elem
    if (!idIsElement) elem = document.getElementById(id)
    else elem = id

    if (elem && !elem.classList.contains('is-hidden')) {
        scrollTimeout = setTimeout(() => {
            scrollIntoViewIfNeeded(
                elem,
                { duration: 800, centerIfNeeded: true, easing: 'easeOut' },
                null,
                null,
                offsetObj
            )
        }, 400)
        return
    }

    scrollTimeout = setTimeout(() => {
        elem = document.getElementById(id)
        if (elem) {
            if (!elem.classList.contains('is-hidden')) {
                scrollIntoViewIfNeeded(
                    elem,
                    { duration: 800, centerIfNeeded: true, easing: 'easeOut' },
                    null,
                    null,
                    offsetObj
                )
            } else {
                scrollTimeout = setTimeout(() => {
                    if (!elem.classList.contains('is-hidden')) {
                        scrollIntoViewIfNeeded(
                            elem,
                            {
                                duration: 800,
                                centerIfNeeded: true,
                                easing: 'easeOut',
                            },
                            null,
                            null,
                            offsetObj
                        )
                    }
                }, 500)
            }
        }
    }, 500)
}

export function scrollTo(element, to, duration) {
    if (duration <= 0) return
    var difference = to - element.scrollTop
    var perTick = (difference / duration) * 10

    setTimeout(function() {
        if (to === 0) {
            window.scrollBy(0, -3000)
            return
        }
        if (window.pageYOffset < to - perTick) {
            window.scrollBy(0, perTick)
            scrollTo(element, to, duration - 10)
        }
        if (window.pageYOffset > to + perTick) {
            window.scrollBy(0, -1 * perTick)
            scrollTo(element, to, duration - 10)
        }
    }, 10)
}

export function countTextPercentage(textCount, maxCount) {
    return (textCount / maxCount) * 100
}

export function loadScreenOn() {
    document.body.className = 'is-loader'
    document.querySelector('#root').className = 'bodyBlur is-blur'
    if (document.querySelector('#wrap'))
        document.querySelector('#wrap').className = 'wrap bodyBlur is-blur'
    return false
}

export function loadScreenOff(inpt) {
    const isLogin = inpt === 'login-page'
    document.body.className = isLogin ? inpt : ''
    if (document.querySelector('#root')) {
        document.querySelector('#root').className = isLogin ? '' : inpt || ''
    }
    if (document.querySelector('#wrap')) {
        document.querySelector('#wrap').className = 'wrap'
    }
    return false
}

export function loginPageShow(showFooter) {
    const nav = document.querySelector('nav')

    if (nav) {
        nav.style.display = 'none'
    }

    if (!showFooter) {
        toggleFooter().hide()
    }
}

export function loginPageHide() {
    document.querySelector('#root').className = ''
    const nav = document.querySelector('nav')
    if (nav) {
        nav.style.display = 'flex'
    }
    toggleFooter().show()
}

export function toggleFooter() {
    const footer = document.querySelector('footer')

    const hide = () => footer && (footer.style.display = 'none')
    // const show = () => footer && (footer.style.display = 'block')
    const show = () => null
    return {
        hide,
        show,
    }
}

export function dataURItoBlob(dataURI) {
    var byteString
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1])
    else byteString = unescape(dataURI.split(',')[1])

    var mimeString = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0]

    var ia = new Uint8Array(byteString.length)
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ia], { type: mimeString })
}

export function translation(key, trans, missingTrans) {
    let retObj = {
        key: key,
        missingTranslations: missingTrans,
    }

    if (trans && trans.hasOwnProperty(key)) {
        let index = retObj.missingTranslations.indexOf(key)
        if (index > -1) retObj.missingTranslations.splice(index, 1)
        if (trans[key]) retObj.key = trans[key]
    } else if (retObj.missingTranslations.indexOf(key) < 0)
        retObj.missingTranslations.push(key)

    return retObj
}

export function setStorage(key, value) {
    if (hasLocalStorage()) {
        localStorage.setItem(key, value)
    } else {
        var storage = new MemoryStorage('skycop-app')
        storage.setItem(key, value)
    }
}

export function getStorage(key) {
    if (hasLocalStorage()) {
        return localStorage.getItem(key)
    } else {
        var storage = new MemoryStorage('skycop-app')
        return storage.getItem(key)
    }
}

export function removeStorage(key) {
    if (hasLocalStorage()) {
        return localStorage.removeItem(key)
    } else {
        var storage = new MemoryStorage('skycop-app')
        storage.removeItem(key)
    }
}

function hasLocalStorage() {
    try {
        localStorage.setItem('hasLocalStorage', 'true')
        let t = localStorage.getItem('hasLocalStorage')
        localStorage.removeItem('hasLocalStorage')
        return t
    } catch (error) {
        return false
    }
}

export function getFlightName(id, postFix) {
    const namePrefixes = {
        1: 'firstFlight',
        2: 'secondFlight',
        3: 'thirdFlight',
        4: 'fourthFlight',
        5: 'fifthFlight',
    }

    if (namePrefixes[id]) return `${namePrefixes[id]}${postFix || ''}`
    else return false
}

export function getAllUrlParams(url) {
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1)
    var obj = {}

    if (queryString) {
        queryString = queryString.split('#')[0]
        var arr = queryString.split('&')

        for (var i = 0; i < arr.length; i++) {
            var a = arr[i].split('=')
            var paramNum = undefined
            var paramName = a[0].replace(/\[\d*\]/, function(v) { // eslint-disable-line
                paramNum = v.slice(1, -1)
                return ''
            })

            var paramValue = typeof a[1] === 'undefined' ? true : a[1]
            paramName = paramName.toLowerCase()

            if (obj[paramName]) {
                if (typeof obj[paramName] === 'string') {
                    obj[paramName] = [obj[paramName]]
                }
                if (typeof paramNum === 'undefined') {
                    obj[paramName].push(paramValue)
                } else {
                    obj[paramName][paramNum] = paramValue
                }
            } else {
                obj[paramName] = paramValue
            }
        }
    }

    return obj
}

export function setCookie(name, value, days, domain = '') {
    var expires = new Date()
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000)
    Cookies.set(name, value, {
        expires,
        domain,
        path: '/',
        sameSite: 'None',
        secure: true,
    })
}

export function getCookie(name) {
    var value = '; ' + document.cookie
    var parts = value.split('; ' + name + '=')
    if (parts.length === 2)
        return parts
            .pop()
            .split(';')
            .shift()
}

export function deleteCookie(
    name,
    domain = _get(window, 'location.origin', '')
) {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;${domain &&
        `domain=${domain};`}`
}

export function getApiClient() {
    return store.getState().api_client || ApiClient
}

export function getStatus(error) {
    return _get(error, 'response.status', '')
}

export function acceptLang(lang) {
    return {
        headers: { 'Accept-language': lang },
    }
}

export function deleteAllCookies() {
    let cookies = document.cookie.split(';')

    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i]
        let eqPos = cookie.indexOf('=')
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
    }
}

export function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )
}

function createOrdinal(day) {
    try {
        const n = parseInt(day, 10)
        return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th'
    } catch (err) {
        return ''
    }
}

export function appendDayOrdinal(day) {
    if (!day) {
        return ''
    }
    if (getStorage('lang') && getStorage('lang') === 'en') {
        return `${day}${createOrdinal(day)}`
    }
    return day
}

// @TODO: To be deprecated/remade for a different purpose
export function getConfigParamValue(inputKey, isFeatureToggle, syncConfig) {
    const config = store.getState().config || syncConfig
    const parseValue = (cfg, key) =>
        cfg
            .filter(i => i.config_key === key || i.configKey === key)
            .map(i => i.value)[0]

    if (isFeatureToggle) {
        if (config) {
            return new Promise(resolve => resolve(parseValue(config, inputKey)))
        } else {
            return store
                .dispatch(getConfig())
                .then(response => parseValue(response, inputKey))
        }
    } else {
        return config ? parseValue(config, inputKey) : ''
    }
}

export async function callAsyncActionWithConfig(func, ...args) {
    try {
        await store.dispatch(getConfig())
        return await func(...args)
    } catch (err) {
        return await func(...args)
    }
}

export async function featureEnabled(key) {
    return (await getConfigParamValue(key, true)) === '1'
}

export function isFeatureEnabled(key) {
    return getConfigVal(key, configTypes.boolean)
}

export function getConfigVal(key, type = configTypes.string) {
    const config = store.getState().config
    if (!configTypes[type] || !config) {
        return false
    }

    const valueItem = config
        .filter(i => (i.config_key || i.configKey) === key)
        .map(i => ({
            type: i.type,
            enabled: i.enabled,
            value: i.value,
        }))[0]

    if (!valueItem) {
        return false
    }

    if (valueItem.type === configTypes.boolean) {
        return !!valueItem.enabled
    } else {
        return valueItem.value
    }
}

export function getParameterByName(name, url) {
    if (!url) url = window.location.href
    name = name.replace(/[\[\]]/g, '\\$&') // eslint-disable-line
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function handleSignatureLength(signature) {
    const length = signature.length
    const dpi = window.devicePixelRatio
    const minLength = 5000
    const actualLength = length / dpi

    return actualLength < minLength
}

export function V2handleSignatureLength(signature, min = 5000) {
    const length = signature.length
    const dpi = window.devicePixelRatio
    const dpiDivision = dpi / 1.5
    const minLength = min * dpi
    const actualLength = length / (dpiDivision < 1 ? 1 : dpiDivision)
    return actualLength < minLength
}

export function getLanguagesArray(webLanguages) {
    if (!webLanguages) {
        return []
    }

    if (webLanguages instanceof Array) {
        return webLanguages.map(lang => ({
            key: lang,
            title: t`language_${lang}_title`,
        }))
    } else if (webLanguages && webLanguages.locales) {
        return (webLanguages.locales || []).sort((a, b) =>
            a.title.localeCompare(b.title)
        )
    }
}

export function isValidEmailInput(value) {
    const startRegex = /^[^a-zA-Z\d]/
    const regex = /[^\sa-zA-Z\d@\.\-\_+]/ // eslint-disable-line
    const numberRegex = /[\d]+/

    if (startRegex.test(value)) {
        return !startRegex.test(value)
    } else {
        if (value.includes('@')) {
            const [email, domain] = value.split('@') // eslint-disable-line

            if (domain) {
                const index = domain.lastIndexOf('.')
                const suffix = domain.substring(index + 1, domain.length)

                if (numberRegex.test(suffix)) {
                    return false
                }
            }
        }

        if (!regex.test(value)) {
            return !regex.test(value)
        }
    }
}

export function isValidEmail(email) {
    const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const nameRegEx = /[^a-zA-Z\d\_@]+$/ // eslint-disable-line

    if (email.includes('@')) {
        const [name] = email.split('@')

        if (nameRegEx.test(name)) {
            return !nameRegEx.test(name)
        }
    }

    return emailRegEx.test(email)
}

export function isValidBookingNumber(number) {
    const regex = /[^A-Z\d]+/
    return !regex.test(number)
}

export function isValidLatinNumbers(value) {
    const regex = /([^a-zA-Z0-9\s])+/
    return !regex.test(value)
}

export function isValidLatinChars(value) {
    const regex = /[^a-zA-Z'\s]+/
    return !regex.test(value)
}

export function isValidSpecialChars(value) {
    const regex = /[^\sa-zA-Z\d,\.:'\+\(\)\?'\-/]/ // eslint-disable-line
    return !regex.test(value)
}

export function changeToUppercase(value) {
    return `${value.charAt(0).toUpperCase()}${value.substr(1)}`
}

export function getAdblockGaStatus() {
    let hasAdblock
    let hasGA

    hasGA =
        typeof window.ga === 'function' &&
        typeof window.ga.create === 'function' &&
        typeof window.dataLayer === 'object' &&
        typeof window.dataLayer.push === 'function'

    if (document.getElementById('bait').offsetHeight === 0) {
        hasAdblock = true
    }

    return `Adblock: ${!!hasAdblock}, GA: ${!!hasGA}`
}

export function getAnalyticsClientId(callback) {
    if (window.ga && window.ga instanceof Function) {
        window.ga(analyticsData => {
            const data = _get(analyticsData, 'b.data', null)
            if (data) {
                const clientId = _get(data, "values[':clientId']", '')
                callback(clientId)
                return clientId
            }
        })
    }
}

export function appendAnalyticsCookie(cookieName, dataName, appendData) {
    const cookie = getCookie(cookieName)
    if (cookie && cookie !== 'undefined') {
        const formDataName = `claim[metadata][analyticsData][${dataName}]`
        appendData(formDataName, cookie)
        return cookie
    } else {
        return
    }
}

export function mapFlightsArray(claim) {
    var flightsArray = []

    if (claim.connectingFlights === 'direct') {
        flightsArray.push({
            airline: claim.failedFlightAirline,
            airportArrival: claim.arrivedTo,
            airportDeparture: claim.departedFrom,
            timeDeparture: moment(claim.failedFlightDate).format(
                FLIGHTDATEFORMAT
            ),
            number: claim.failedFlightNumberDigits,
            flightOrder: 0,
        })
    } else {
        for (let i = 1; i < parseInt(claim.connectingFlights, 0) + 2; i++) {
            if (i - 1 === claim.failedFlightArrayIndex) {
                flightsArray.push({
                    airline: claim.failedFlightAirline,
                    airportArrival: claim[getFlightName(i, 'To')],
                    airportDeparture: claim[getFlightName(i, 'From')],
                    timeDeparture: moment(claim.failedFlightDate).format(
                        FLIGHTDATEFORMAT
                    ),
                    number: claim.failedFlightNumberDigits,
                    flightOrder: i - 1,
                })
            } else {
                flightsArray.push({
                    airline: claim[getFlightName(i, 'Airline')],
                    airportArrival: claim[getFlightName(i, 'To')],
                    airportDeparture: claim[getFlightName(i, 'From')],
                    timeDeparture: moment(
                        claim[getFlightName(i, 'Date')]
                    ).format(FLIGHTDATEFORMAT),
                    number: claim[getFlightName(i, 'NumberDigits')],
                    flightOrder: i - 1,
                })
            }
        }
    }
    return flightsArray
}

export function mapFormData(value, parentObject, zeroValue) {
    const fd = new FormData()
    for (const key in value) {
        if (value.hasOwnProperty(key)) {
            if (typeof value[key] !== 'object') {
                if (zeroValue || value[key]) {
                    fd.append(`${parentObject}[${key}]`, `${value[key]}`)
                }
            } else {
                for (const nKey in value[key]) {
                    if (typeof value[key][nKey] !== 'object') {
                        if (zeroValue || value[key][nKey]) {
                            fd.append(
                                `${parentObject}[${key}][${nKey}]`,
                                `${value[key][nKey]}`
                            )
                        }
                    } else {
                        for (const nnKey in value[key][nKey]) {
                            fd.append(
                                `${parentObject}[${key}][${nKey}][${nnKey}]`,
                                `${value[key][nKey][nnKey]}`
                            )
                        }
                    }
                }
            }
        }
    }

    return fd
}

export function mapClaimData(claim, clmAmount) {
    const flightsArray = mapFlightsArray(claim)

    let detailsArray = []
    for (let c = 1; c < 5; c++) {
        if (claim[`reason${c}`]) {
            detailsArray.push({
                value: claim[`reason${c}`],
                level: c,
            })
        }
    }

    return {
        timeDeparture: moment(claim.failedFlightDate).format(FLIGHTDATEFORMAT),
        airportArrival: claim.arrivedTo,
        airportDeparture: claim.departedFrom,
        reservationNumber:
            claim.bookingNumber && claim.bookingNumber.substr(0, 15),
        claimable: 'true',
        comment: claim.comments && claim.comments.substr(0, 1500),
        problemClaimFlight: claim.failedFlightArrayIndex,
        details: detailsArray,
        flights: flightsArray,
        passengers: claim.passengers,
        price: clmAmount,
        partnerId: getCookie('idev') ? getCookie('idev').split('-')[0] : '',
        user: {
            birthdate: claim.userBirthDate,
            name: claim.userName,
            surname: claim.userSurname,
            email: claim.userEmail,
            phoneCode: claim.userPhoneCode,
            phone:
                '+' +
                claim.userPhone
                    .replace('+', '')
                    .replace('(', '')
                    .replace(')', '')
                    .replace(/\s+/g, '')
                    .replace(/-/g, ''),
            address: claim.userAddress,
            postCode: claim.userPostCode,
            city: claim.userCity,
            country: claim.userCountry,
            locale: claim.userLanguage,
            subscribe: claim.subscribe || null,
        },
    }
}

export function mapClaimPostData(claim, clmAmount, props) {
    const {
        user,
        saveUserClaim,
        getPayoutPlans,
        postUserClaim,
        setClaimableAmount,
        updateUserInfo,
    } = props
    const claimData = mapClaimData(claim, clmAmount)
    let documentsArray = []

    if (user && !user.birthdate) {
        const userData = claimData.user
        let fd = mapFormData(userData, 'user_edit')

        updateUserInfo(fd, null, null, true)
    }

    claim.clmAmount = clmAmount
    let passengerQuantity = 1 + claim.passengers.length

    if (claim.filesSelected && claim.filesSelected.length > 0) {
        for (var i = 0; i < claim.filesSelected.length; i++) {
            const reader = new FileReader()
            let fileIndex = i
            let fileName = claim.filesSelected[i].name
            reader.readAsDataURL(claim.filesSelected[i])
            reader.onload = () => {
                if (fileIndex === claim.filesSelected.length - 1) {
                    documentsArray.push({
                        file: dataURItoBlob(reader.result),
                        name: fileName,
                        type: 'res',
                    })

                    let fd = mapFormData(claimData, 'claim', true)

                    if (claim.clientId) {
                        fd.append(
                            'claim[metadata][analyticsData][clientId]',
                            claim.clientId
                        )
                    }

                    setTimeout(() => {
                        for (let b = 0; b < documentsArray.length; b++) {
                            const docIndex = b
                            fd.append(
                                `claim[documents][${docIndex}][file]`,
                                documentsArray[docIndex].file,
                                documentsArray[docIndex].name
                            )
                            fd.append(
                                `claim[documents][${docIndex}][type]`,
                                documentsArray[docIndex].type
                            )
                        }

                        saveUserClaim(claim)
                        getPayoutPlans(clmAmount)
                        postUserClaim(
                            fd,
                            claim.userLanguage,
                            claim.filesSelected,
                            claim.about_us || '',
                            passengerQuantity,
                            !!user,
                            claim.idev_tid1
                        )
                        setClaimableAmount(clmAmount)
                    }, 1000)
                } else {
                    documentsArray.push({
                        file: dataURItoBlob(reader.result),
                        name: fileName,
                        type: 'res',
                    })
                }
            }
        }
    } else {
        let fd = mapFormData(claimData, 'claim', true)

        if (claim.clientId) {
            fd.append(
                'claim[metadata][analyticsData][clientId]',
                claim.clientId
            )
        }

        saveUserClaim(claim)
        getPayoutPlans(clmAmount)
        postUserClaim(
            fd,
            claim.userLanguage,
            claim.filesSelected,
            claim.about_us || '',
            passengerQuantity,
            !!user,
            claim.idev_tid1
        )
        setClaimableAmount(clmAmount)
    }
}

export function isProduction() {
    if (process && process.env && process.env.REACT_APP_ENV) {
        return process.env.REACT_APP_ENV === 'production'
    } else {
        return true
    }
}

export function disableExtensions() {
    // disable react-dev-tools
    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
        for (let [key, value] of Object.entries(
            window.__REACT_DEVTOOLS_GLOBAL_HOOK__
        )) {
            window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] =
                typeof value === 'function' ? () => {} : null
        }
    }
    // disable redux-dev-tools
    if (typeof window.devToolsExtension === 'function') {
        window.devToolsExtension = () => {}
    }
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function') {
        window.__REDUX_DEVTOOLS_EXTENSION__ = () => {}
    }
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ = () => {}
    }
}

export function triggerAffiliatePixel(clickId, claimId) {
    const config = store.getState().config

    if (!config || !clickId) {
        return
    }

    const affiliateListString = getConfigVal('affiliates_list')
    let parsedAffiliateList
    try {
        parsedAffiliateList = JSON.parse(affiliateListString)
    } catch (e) {
        if (!isProduction()) {
            console.log(e)
        }
    }

    const data = _get(parsedAffiliateList, 'data', null)
    if (!data) {
        return
    }

    const idevId = getCookie('idev') ? getCookie('idev').split('-')[0] : ''
    const selectedAffiliate = data.filter(p => p.id === idevId)[0]
    if (typeof selectedAffiliate === 'object' && selectedAffiliate.clickUrl) {
        axios
            .get(
                `${selectedAffiliate.clickUrl}?clickid=${clickId ||
                    ''}&orderid=${claimId || ''}`
            )
            .then(() => {})
            .catch(() => {})
    }
}

export function setAffiliateCookie(name, value) {
    if (!name || !value) {
        return
    }

    setCookie(name, value, 90, '.skycop.com')
}

export function deleteAffiliateCookies() {
    deleteCookie('partner_medium')
    deleteCookie('partner_tid1')
}

export function handleBrandedRedirect(params) {
    const subdomain = params.idev_tid2
    const partnerId = params.idev_id
    delete params.idev_tid2
    delete params.idev_id

    if (!subdomain || !partnerId) {
        return
    }

    let paramsUrl = ''
    for (let key in params) {
        if (paramsUrl) {
            paramsUrl += '&'
        }
        paramsUrl += `${key}=${encodeURIComponent(params[key])}`
    }

    const partnerTheme = getConfigVal(`partner_${partnerId}`)
    const theme = window.btoa(partnerTheme)

    if (!partnerTheme || !theme) {
        return
    }

    const redirectUrl = `https://${subdomain}.skycop.com?${paramsUrl}&theme=${theme}`
    window.location.href = redirectUrl
}

export function flightResults(query, name, usedAirports = []) {
    const selectedAirports = store.getState().v2_selectedAirports || []
    if (!query || query.length < 2) {
        return Promise.resolve({
            options: arrayUnique([...selectedAirports, ...usedAirports]).filter(
                a => a.name === name
            ),
        })
    }

    return store.dispatch(getAirports(query, name)).then(({ payload }) => {
        const options = renderAirports(payload, name, selectedAirports)
        store.dispatch({
            type: V2_ACTIONS.USED_AIRPORTS,
            payload: options,
        })
        return {
            options,
        }
    })
}

export function airlineResults(query, name, usedAirlines = [], isIata = false) {
    if (!query || query.length < 2) {
        return Promise.resolve({ options: usedAirlines })
    }

    return store.dispatch(getAirlines(query, isIata)).then(payload => {
        const options = renderAirlines(payload, name, [])
        store.dispatch({
            type: V2_ACTIONS.USED_AIRLINES,
            payload: options,
        })
        return {
            options,
        }
    })
}

export const isEmpty = obj => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false
        }
    }
    return true
}

export const bytesToSize = bytes => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return 'n/a'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
    if (i === 0) return `${bytes} ${sizes[i]})`
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
}

export const orginalSuffix = i => {
    var j = i % 10,
        k = i % 100
    if (j === 1 && k !== 11) {
        // eslint-disable-line
        return i + 'st'
    }
    if (j === 2 && k !== 12) {
        // eslint-disable-line
        return i + 'nd'
    }
    if (j === 3 && k !== 13) {
        // eslint-disable-line
        return i + 'rd'
    }
    return i + 'th'
}

export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
}

export const getAgeInYears = (birthdate, flightDate) => {
    if (!birthdate || !flightDate) {
        return 0
    }

    return moment(flightDate).diff(moment(birthdate), 'year')
}

const pathLayerActions = {
    [paths.V2.STEP_1_FLIGHT_ITINERARY]: data => {
        const flightDeparture = _get(
            getAirportById(data.airportDeparture),
            'iata',
            ''
        )
        const flightDestination = _get(
            getAirportById(data.airportArrival),
            'iata',
            ''
        )
        const flightDirect = (data.flights || []).length === 1 ? 'Yes' : 'No'

        if (flightDeparture && flightDestination && flightDirect) {
            return {
                event: 'Step1',
                flightDeparture: flightDeparture,
                flightDestination: flightDestination,
                flightDirect: flightDirect,
            }
        }

        return null
    },
    [paths.V2.STEP_2_DISRUPTION_DETAILS]: data => {
        const disruptionType = getEnTrans(_get(data, 'details[0].value', null))
        const disruptionDuration = getEnTrans(
            _get(data, 'details[1].value', null)
        )

        if (disruptionType && disruptionDuration) {
            return {
                event: 'Step2',
                disruptionType: disruptionType,
                disruptionDuration: disruptionDuration,
            }
        }

        return null
    },
    [paths.V2.STEP_3_DISRUPTION_REASON]: data => {
        const disruptionReason = _get(
            data,
            'details[3].value',
            _get(data, 'details[2].value', null)
        )

        if (disruptionReason) {
            return {
                event: 'Step3',
                disruptionReason: disruptionReason,
            }
        }

        return null
    },
    [paths.V2.STEP_4_FLIGHT_DETAILS]: data => {
        const { problemClaimFlight, flights } = data
        const flight = flights[problemClaimFlight]
        const airline = (store.getState().v2_UsedAirlines || []).find(
            a => a.id === flight.airline
        )
        const flightAirlines = _get(airline, 'title', '')
        const flightDate = flight.timeDeparture
        const flightNumber = `${_get(airline, 'iata', '')}${flight.number}`

        if (flightAirlines && flightDate && flightNumber) {
            return {
                event: 'Step4',
                flightAirlines: flightAirlines,
                flightDate: flightDate,
                flightNumber: flightNumber,
            }
        }

        return null
    },
    [paths.V2.STEP_5_PASSENGER_DETAILS]: (_, postedDate) => {
        const passengers = _get(postedDate, 'passengers', [])
        const numberOfPassengers = 1 + passengers.length

        if (numberOfPassengers) {
            return {
                event: 'Step5',
                numberOfPassengers: `${numberOfPassengers}`,
            }
        }

        return null
    },
    [paths.V2.STEP_6_CLAIM_SIGNATURE]: () => {
        return {
            event: 'Step6',
        }
    },
    [paths.V2.STEP_7_FLIGHT_DOCUMENTS]: data => {
        const { documents } = data
        const flightDocuments = (documents || []).length > 0 ? 'Yes' : 'No'

        if (flightDocuments) {
            return {
                event: 'Step7',
                flightDocuments: flightDocuments,
            }
        }

        return null
    },
}

function getActionByPath(path) {
    const claimData = store.getState().V2Claim || {}
    const postedDate = store.getState().v2_posted_claim || {}
    const data = _get(pathLayerActions, path, () => null)(claimData, postedDate)

    if (!data) {
        // eslint-disable-next-line
        if (Raven) {
            // eslint-disable-next-line
            // Raven.captureException(`V2 ANALYTICS PUSH FAILED FOR PATH: ${path}. Claim Data: ${JSON.stringify(claimData)}`);
        }

        return null
    } else {
        // eslint-disable-next-line
        return () => dataLayer.push(data)
    }
}

export const pushDatalayerByPath = path => {
    const action = getActionByPath(path)

    if (typeof action === 'function') {
        action()
    }
}

export const mapLocale = loc => {
    switch (loc) {
        case 'ee':
            return 'et' // Estonian
        case 'ua':
            return 'uk' // Ukrainian
        default:
            return loc || 'en'
    }
}

export const mapCalendarLocale = loc => {
    switch (loc) {
        case 'ee':
            return 'et'
        case 'ua':
            return 'uk'
        case 'zh':
            return 'zh-cn'
        case 'al':
            return 'sq'
        case 'hy':
            return 'hy-am'
        case 'no':
            return 'nb'
        case 'ar':
            return 'en'
        default:
            return loc || 'en'
    }
}
export const showRandomModal = claimId => {
    if (isFeatureEnabled(featureToggles.feedbackScreens)) {
        const feedbackModals = [
            {
                modal: modalTypes.ratingModal,
                weight: 1,
            },
            {
                modal: modalTypes.socialShareModal,
                weight: 0,
            },
        ]

        const totalWeight = feedbackModals.reduce((prev, acc) => {
            return prev + acc.weight
        }, 0)
        let weighedModals = []

        feedbackModals.forEach(f => {
            for (let i = 0; i < f.weight; i++) {
                weighedModals.push(f.modal)
            }
        })

        const randomModal = Math.floor(Math.random() * totalWeight)
        if (claimId) {
            store.dispatch(
                logKibana(
                    `${weighedModals[randomModal]}, claimId: ${claimId}`,
                    '/thanks',
                    'warning'
                )
            )
        }
        store.dispatch(showModal(weighedModals[randomModal]))
    }
}

// Replace <span></span> or any other separated translation with actions
export const applyTranslationAction = (
    trans = '',
    firstKey = '',
    lastKey = '',
    action = () => null,
    className = '',
    dangerous
) => {
    if (store && trans && store.getState().translations) {
        const [firstPart, secondPart] = (trans || '').split(firstKey)
        const [midPart, lastPart] = (secondPart || '').split(lastKey)

        if (dangerous) {
            return (
                <React.Fragment>
                    <span dangerouslySetInnerHTML={{ __html: firstPart }} />
                    <span className={className} onClick={action}>
                        {midPart}
                    </span>
                    <span dangerouslySetInnerHTML={{ __html: lastPart }} />
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    {firstPart}
                    <span className={className} onClick={action}>
                        {midPart}
                    </span>
                    {lastPart}
                </React.Fragment>
            )
        }
    } else {
        return ''
    }
}

// Replace a translation part with an element
export const applyTranslationElement = (
    trans = '',
    replacePart = '',
    element,
    dangerous,
    space = ''
) => {
    if (store && trans && store.getState().translations) {
        const [firstPart, secondPart] = trans.split(replacePart)

        if (dangerous) {
            return (
                <React.Fragment>
                    <span dangerouslySetInnerHTML={{ __html: firstPart }} />
                    {space}
                    {element}
                    <span dangerouslySetInnerHTML={{ __html: secondPart }} />
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    {firstPart}
                    {element}
                    {secondPart}
                </React.Fragment>
            )
        }
    } else {
        return ''
    }
}

const preventWindowClose = e => {
    if (e && typeof e.preventDefault === 'function') {
        e.preventDefault()
    }
    return t`loader.loading_files`
}

export const addBeforeUnload = (altFunc = () => null) => {
    const cb = e => {
        if (isFeatureEnabled(featureToggles.featureOnClose)) {
            altFunc()
            return preventWindowClose(e)
        }
    }
    window.onbeforeunload = e => cb(e)
    window.unload = e => cb(e)
    window.onpagehide = e => cb(e)
    document.pagehide = e => cb(e)
}

export const removeBeforeUnload = () => {
    window.onbeforeunload = null
    window.unload = null
    window.onpagehide = null
    document.pagehide = null
}

export const showInactiveDraftModal = (cb = () => null) => {
    store.dispatch(showModal(modalTypes.claimDraftModal))
    cb()
}

export function formatDate(date) {
    return date ? moment(date).format('YYYY-MM-DD') : ''
}
