import React from 'react'
import { connect } from 'react-redux'
import FileDragAndDrop from 'react-file-drag-and-drop'

import styled from 'styled-components'
import t from '../../../../shared/translations'
import { Col, Row } from '../../../../shared/components/V2/Layout'
import _ from 'lodash'
import { isMobileDevice } from '../../../../main'
import { Reupload } from '../../../../shared/components/svgIcons'
import {
    VerticalRadio,
    ImageRadio,
} from '../../../../shared/components/V2/RadioButtons'
import { mobile } from '../../../../shared/components/helpers/styled_queries'
import {
    FlightAlternative,
    Bus,
    Train,
    DontRemember,
} from '../../../../shared/components/V2/SkycopPay/icons'
import { alternativeTransports, CloseCircle } from './index'
import {
    UploadIconV2,
    Document,
} from '../../../../shared/components/V2/V2Icons'
import {
    FileInputLabel,
    FileInput,
    FileUploadText,
    TextContainer,
    IconDisplay,
    DragTitle,
    DragDescription,
    UploadedDocsWrapper,
    DocumentWrapper,
    IconWrapper,
    DocumentStatusWrapper,
    ClearIconWrapper,
    DocumentTitle,
    DocumentStatus,
    ReuploadWrapper,
    ReuploadTitle,
    StyledErrorsBox,
    StyledStepContent,
} from './flightDocumentsStyles'

const DragDropContainer = styled.div`
    height: 200px;
    background-color: #f5f5f5;
    margin: 1px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='rgb(113, 113, 113)' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 16px;

    &.hover,
    &:hover {
        border-color: #00bc61;
        /* 
        .inputText:before {
            background-image: url("/icons/v2_upload-icon-green.svg");
        } */
    }
`

const StyledRow = styled(Row)`
    margin-bottom: 24px;
    ${mobile`
        padding-right: 16px;
        `}
`

const TitleWrapper = styled(StyledRow)`
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    margin-bottom: 4px;
    margin-top: 26px;
    padding-right: 16px;

    ${mobile`
        padding-left: 16px;
        `}
`

const DragDropTitle = styled.div`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    color: #717171;
`

class AlternativeTransportation extends React.Component {
    getCustomReasons = () => {
        const {
            hasAlternativeTransport,
            handleHasAlternativeTransportChange,
        } = this.props

        return [
            {
                name: 'has_alternative_reason',
                value: hasAlternativeTransport,
                onChange: handleHasAlternativeTransportChange,
                checked: hasAlternativeTransport === 'has_alternative_reason',
                label: t`yes`,
                id: 'yes-provided',
            },
            {
                name: 'has_no_alternative_reason',
                value: hasAlternativeTransport,
                onChange: handleHasAlternativeTransportChange,
                checked:
                    hasAlternativeTransport === 'has_no_alternative_reason',
                label: t`v2_common.label.no`,
                id: 'no-provided',
            },
        ]
    }

    getImageReasons = () => {
        const {
            alternativeTransportType,
            handleAlternativeTransportTypeChange,
        } = this.props

        return [
            {
                name: alternativeTransports.ALTERNATIVE_TRANSPORT_FLIGHT,
                checked:
                    alternativeTransportType ===
                    alternativeTransports.ALTERNATIVE_TRANSPORT_FLIGHT,
                value: alternativeTransportType,
                onChange: handleAlternativeTransportTypeChange,
                label: t`alternative.trans.page.what.alternative.transport.flight`,
                id: '1',
                icon: (
                    <FlightAlternative
                        stroke={
                            alternativeTransportType ===
                                alternativeTransports.ALTERNATIVE_TRANSPORT_FLIGHT &&
                            '#149954'
                        }
                    />
                ),
            },
            {
                name: alternativeTransports.ALTERNATIVE_TRANSPORT_TRAIN,
                checked:
                    alternativeTransportType ===
                    alternativeTransports.ALTERNATIVE_TRANSPORT_TRAIN,
                value: alternativeTransportType,
                onChange: handleAlternativeTransportTypeChange,
                label: t`alternative.trans.page.what.alternative.transport.train`,
                id: '2',
                icon: (
                    <Train
                        stroke={
                            alternativeTransportType ===
                                alternativeTransports.ALTERNATIVE_TRANSPORT_TRAIN &&
                            '#149954'
                        }
                    />
                ),
            },
            {
                name: alternativeTransports.ALTERNATIVE_TRANSPORT_BUS_CAR,
                checked:
                    alternativeTransportType ===
                    alternativeTransports.ALTERNATIVE_TRANSPORT_BUS_CAR,
                value: alternativeTransportType,
                onChange: handleAlternativeTransportTypeChange,
                label: t`alternative.trans.page.what.alternative.transport.bus.car`,
                id: '3',
                icon: (
                    <Bus
                        stroke={
                            alternativeTransportType ===
                                alternativeTransports.ALTERNATIVE_TRANSPORT_BUS_CAR &&
                            '#149954'
                        }
                    />
                ),
            },
            {
                name: alternativeTransports.ALTERNATIVE_TRANSPORT_DR,
                checked:
                    alternativeTransportType ===
                    alternativeTransports.ALTERNATIVE_TRANSPORT_DR,
                value: alternativeTransportType,
                onChange: handleAlternativeTransportTypeChange,
                label: t`alternative.trans.page.what.alternative.transport.dont.remember`,
                id: '4',
                icon: (
                    <DontRemember
                        stroke={
                            alternativeTransportType ===
                                alternativeTransports.ALTERNATIVE_TRANSPORT_DR &&
                            '#149954'
                        }
                    />
                ),
            },
        ]
    }

    handleReupload = file => {
        const { claimId, uploadDocument } = this.props
        // add action
        uploadDocument(file, claimId)
    }

    renderDocumentsListSeparate = (documents, failedDocuments) => {
        const {
            removeUserDocument,
            handleReupload,
            clearAlternativeUploadErrors,
        } = this.props
        if (
            (documents && !!documents.length) ||
            (failedDocuments && !!failedDocuments.length)
        ) {
            const docs = documents.map((document, index) => {
                const { file, progress } = document
                const { name } = file
                const isSuccess = progress === 100
                const isLoading = progress < 100

                return (
                    <DocumentWrapper>
                        <IconWrapper>
                            <Document />
                        </IconWrapper>
                        <DocumentStatusWrapper>
                            <DocumentTitle>{name}</DocumentTitle>
                            <DocumentStatus
                                isLoading={isLoading}
                                failed={false}
                            >
                                {isSuccess
                                    ? t`upload_document_success`
                                    : t`upload_document_uploading`}
                            </DocumentStatus>
                        </DocumentStatusWrapper>
                        <ClearIconWrapper
                            onClick={() => {
                                removeUserDocument(document.id)
                            }}
                        >
                            <CloseCircle />
                        </ClearIconWrapper>
                    </DocumentWrapper>
                )
            })

            const failedDocs = failedDocuments.map((document, index) => {
                const { file, progress } = document
                const name = file && file.name
                const isSuccess = progress === 100

                return (
                    <DocumentWrapper>
                        <IconWrapper>
                            <Document />
                        </IconWrapper>
                        <DocumentStatusWrapper>
                            <DocumentTitle>{name}</DocumentTitle>
                            <DocumentStatus
                                isLoading={false}
                                failed={!isSuccess}
                            >
                                {isSuccess
                                    ? t`upload_document_uploading`
                                    : t`upload_document_failed`}
                            </DocumentStatus>
                            {!isSuccess && (
                                <ReuploadWrapper
                                    onClick={() => handleReupload(file)}
                                >
                                    <ReuploadTitle>{t`reupload_document`}</ReuploadTitle>
                                    <Reupload />
                                </ReuploadWrapper>
                            )}
                        </DocumentStatusWrapper>
                        <ClearIconWrapper
                            onClick={() => clearAlternativeUploadErrors()}
                        >
                            <CloseCircle />
                        </ClearIconWrapper>
                    </DocumentWrapper>
                )
            })

            return (
                <div>
                    {docs}
                    {failedDocs}
                </div>
            )
        }
    }

    render() {
        const {
            trans,
            handleDrop,
            fileOnDragOver,
            fileOnDragLeave,
            hasAlternativeTransport,
            componentState,
            dragAlternativeContainer,
            handleFileChange,
            flightDocsAlternative,
        } = this.props
        if (!trans) return null

        const documentsExist = !!flightDocsAlternative.documents.length

        return (
            <div>
                <StyledRow>
                    <VerticalRadio
                        label={t`alternative.trans.page.privided.transportation.question`}
                        required
                        options={this.getCustomReasons()}
                    />
                </StyledRow>

                {hasAlternativeTransport != null && (
                    <div>
                        {hasAlternativeTransport ===
                            alternativeTransports.HAS_ALTERNATIVE_REASON && (
                            <Row>
                                <ImageRadio
                                    mobileDirection="column"
                                    required
                                    id="second-row-reasons"
                                    label={t`alternative.trans.page.what.alternative.transport.question`}
                                    options={this.getImageReasons()}
                                />
                            </Row>
                        )}

                        <TitleWrapper>
                            <DragDropTitle>
                                {hasAlternativeTransport ===
                                alternativeTransports.HAS_ALTERNATIVE_REASON
                                    ? t`alternative.trans.page.documents.upload.receipts.title`
                                    : t`alternative.trans.page.documents.upload.receipts.myself.title`}
                            </DragDropTitle>
                            <DragDescription>
                                {t`alternative.trans.page.documents.upload.receipts.description`}
                            </DragDescription>
                        </TitleWrapper>
                        <FileDragAndDrop
                            onDrop={handleDrop}
                            onDragOver={fileOnDragOver}
                            onDragLeave={fileOnDragLeave}
                            onDragEnd={fileOnDragLeave}
                        >
                            <StyledStepContent>
                                {!!_.get(
                                    componentState,
                                    'uploadErrorsAlternative.length',
                                    0
                                ) && (
                                    <Row>
                                        <Col>
                                            <StyledErrorsBox>
                                                <ul>
                                                    {componentState.uploadErrorsAlternative.map(
                                                        (err, i) => (
                                                            <li key={i}>
                                                                {err.name}
                                                                &nbsp;-&nbsp;
                                                                {err.error.toLowerCase()}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </StyledErrorsBox>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col md={12} lg={12}>
                                        <DragDropContainer
                                            innerRef={dragAlternativeContainer}
                                        >
                                            <FileInputLabel>
                                                <FileInput
                                                    type="file"
                                                    id="file"
                                                    name="fileselect[]"
                                                    multiple="multiple"
                                                    onChange={handleFileChange}
                                                    accept="image/*,application/msword,application/vnd.oasis.opendocument.text,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                />
                                                <FileUploadText
                                                    className="inputText"
                                                    id="dd-box"
                                                >
                                                    <TextContainer>
                                                        <IconDisplay>
                                                            <UploadIconV2 />
                                                            <DragTitle>
                                                                {isMobileDevice()
                                                                    ? t`v2_claim_documents_drag_title_mobile`
                                                                    : t`v2_claim_documents_drag_title_desktop`}
                                                            </DragTitle>
                                                        </IconDisplay>
                                                        <DragDescription>
                                                            {t`v2_claim_documents_drag_description`}
                                                        </DragDescription>
                                                    </TextContainer>
                                                </FileUploadText>
                                            </FileInputLabel>
                                        </DragDropContainer>
                                    </Col>
                                </Row>
                                {(!!_.get(
                                    componentState,
                                    'uploadErrors.length',
                                    0
                                ) ||
                                    (componentState.canRenderAlternative &&
                                        documentsExist)) && (
                                    <UploadedDocsWrapper>
                                        <Col
                                            className="pdt-0 pdb-0"
                                            md={12}
                                            sm={12}
                                            lg={12}
                                        >
                                            {this.renderDocumentsListSeparate(
                                                flightDocsAlternative.documents,
                                                componentState.uploadErrorsAlternative
                                            )}
                                        </Col>
                                    </UploadedDocsWrapper>
                                )}
                            </StyledStepContent>
                        </FileDragAndDrop>
                    </div>
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        flightDocsAlternative: state.v2_user_documents,
    }
}

export default connect(mapStateToProps, null, null, {
    withRef: true,
})(AlternativeTransportation)
